import InstaceAxios  from "./Api";

export const getReporteVentaDetallda = async (fecha_ini, fecha_fin, modalidad, tipo_comercio, id_comercio, detalle) => {
    const result = await InstaceAxios.get(`reporte/venta/detallada?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}&modalidad=${modalidad}&tipo_comercio=${tipo_comercio}&id_comercio=${id_comercio}&detalle=${detalle}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getDetalleVenta = async (id_transaccion) => {
    const result = await InstaceAxios.get(`venta/mis_ventas/detalle?id_transaccion=${id_transaccion}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getReporteConsumoSMS = async (fecha_ini, fecha_fin, agrupado) => {
    const result = await InstaceAxios.get(`reporte/sms/consumo?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}&agrupado=${agrupado}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getReportePrestamos = async (fecha_ini, fecha_fin) => {
    const result = await InstaceAxios.get(`reporte/empresa/prestamos?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getReportePrestamosComercio = async (fecha_ini, fecha_fin) => {
    const result = await InstaceAxios.get(`reporte/prestamos?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getReporteExtractoPrestamosComercio = async (fecha_ini, fecha_fin) => {
    const result = await InstaceAxios.get(`reporte/prestamos/extracto?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_mis_abonos = async (fecha_ini, fecha_fin) => {
    const result = await InstaceAxios.get(`reporte/abonos/comercio?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_abonos_realizados = async (fecha_ini, fecha_fin) => {
    const result = await InstaceAxios.get(`reporte/abonos/realizados?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getReporteIngresoEgresos = async (fecha_ini, fecha_fin, id_comercio) => {
    const result = await InstaceAxios.get(`reporte/ingresosEgresos?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}&id_comercio=${id_comercio}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getResumenResumenIngresosEgresos = async (fecha_ini, fecha_fin) => {
    const result = await InstaceAxios.get(`reporte/resumen/ingresosEgresos?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getReportePrepago = async (fecha_ini, fecha_fin, id_comercio) => {
    const result = await InstaceAxios.get(`reporte/prepago?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}&id_comercio=${id_comercio}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getReporteVentaLoteria = async (fecha_ini, fecha_fin, id_loteria, id_categoria_modalidad_juego) => {
    const result = await InstaceAxios.get(`reporte/venta/loteria?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}&id_loteria=${id_loteria}&id_categoria_modalidad_juego=${id_categoria_modalidad_juego}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getReporteLiquidacion = async (fecha_ini, fecha_fin, tipo_comercio) => {
    const result = await InstaceAxios.get(`reporte/liquidacion?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}&tipo_comercio=${tipo_comercio}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getTicket = async (id_transaccion) => {
    const result = await InstaceAxios.get(`reporte/ticket?id_transaccion=${id_transaccion}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getReporteCubiertaExtracto = async (fecha_ini) => {
    const result = await InstaceAxios.get(`reporte/cubierta/extracto?fecha_ini=${fecha_ini}`)
        .then(res => res)
        .catch(error => error)
    return result
}


export const getReporteCubierta = async (fecha_ini) => {
    const result = await InstaceAxios.get(`reporte/cubierta?fecha_ini=${fecha_ini}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getCartera = async (fecha_ini = "", fecha_fin = "", id_comercio = "", tipo_comercio = "VENDEDOR" ) => {
    const result = await InstaceAxios.get('reporte/cartera?fecha_ini='+fecha_ini+'&fecha_fin='+fecha_fin+'&id_comercio='+id_comercio+'&tipo_comercio='+tipo_comercio)
        .then(res => res)
        .catch(error => error)
    return result
}