import React  from 'react';
import { connect } from 'react-redux';

function Hoy(props){

    const lista_hoy = props.loterias_hoy.data;

    return(
        <React.Fragment>
            <div className="card">
                <div className="card-body">
                <h5 className="card-title">Loterias y resultados <span>| Hoy</span></h5>
                    <div className="accordion" id="accordionExample">
                    { Array.isArray(lista_hoy) ? lista_hoy.map((hoy, index) => (
                        <div className="accordion-item" key={index}>
                            <h2 className="accordion-header">
                            <button className="accordion-button fs-5" type="button" data-bs-toggle="collapse" data-bs-target={`#${index}`} aria-expanded="true" aria-controls={index}>
                                {hoy.prefijo}
                            </button>
                            </h2>
                            <div id={index} className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <ul className="list-group">
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            Disponiblidad
                                            <span className={hoy.disponibilidad == 'abierta' ? 'text-success' : 'text-danger' }>{hoy.disponibilidad}</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            Resultado
                                            <div>
                                                <b className='fs-6'>{hoy.numero} {hoy.serie} {hoy.signo}</b>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>   
                    )) : '' }                     
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {
    return {
        loterias_hoy: state.loterias_hoy
    }
}
export default  connect(mapSateToProps, null)(Hoy);