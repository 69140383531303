import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { crearEmpresa, EditarEmpresa } from '../../../../services/root'
import { alerta, toast, cerrarModal } from '../../../../services/utilities';
import { validar_nickname } from '../../../../services/Usuario'
import { getEmpresa } from '../../../../services/root'
import Swal  from 'sweetalert2';

function Formulario (props){

    useEffect(() => {

        if(props.id_comercio !== 0){
            handleGetEmpresa(props.id_comercio);
        }else{
            setEmpresa({
                "id_comercio" : 0,
                "id_empresa" : 0,
                "nombre":"",
                "nombre_contacto":"",
                "telefono_contacto":"",
                "correo":"",
                "estado_id":1,
                "envia_sms":1,
                "costo_sms":1,
                "dominio":"",
                "resultados_automaticos":1,
                "realiza_prestamos":1,
                "tipo_envio_comprobante_wp":"agenda",
                "sms_opcional":0,
                "opera_decimales":0,
                "impresora": 0,
                "venta_cubierta":0,
                "comercio_cubierta_id":'',
                "monto_minimo_venta":0,
                "boton_wp":1,        
                "apuesta":1,
                "quinta":1,
                "astros":1,
                "rifa":0,
                "bonos":0,
                "comision_apuesta":100,
                "comision_quinta":100,
                "comision_astros":100,
                "comision_rifa":100,
                "comision_bonos":35,
                "segunda_clave":"",
                "nickname":"",
                "password":"",
                "usuarios":[]
            });
            setListaUsuarios('');
        }

    },[props.id_comercio]);

    const [spiner, setSpiner] = useState(false);
    const [lista_usuarios, setListaUsuarios] = useState('');

    const [empresa, setEmpresa] = useState({
        "id_comercio" : 0,
        "id_empresa" : 0,
        "nombre":"",
        "nombre_contacto":"",
        "telefono_contacto":"",
        "correo":"",
        "estado_id":1,
        "envia_sms":1,
        "costo_sms":1,
        "dominio":"",
        "resultados_automaticos":1,
        "realiza_prestamos":1,
        "tipo_envio_comprobante_wp":"agenda",
        "sms_opcional":0,
        "opera_decimales":0,
        "monto_minimo_venta":0,
        "boton_wp":1,        
        "apuesta":1,
        "quinta":1,
        "astros":1,
        "rifa":0,
        "bonos":0,
        "comision_apuesta":100,
        "comision_quinta":100,
        "comision_astros":100,
        "comision_rifa":100,
        "comision_bonos":35,
        "segunda_clave":"",
        "nickname":"",
        "password":"",
        "usuarios":[]
    });

    const handleGetEmpresa = async (id_empresa) => {

        setSpiner(true);

        try {
            await getEmpresa(id_empresa).then(response => {
                if(response.status === 200){
                    setEmpresa(response.data.empresa);
                    setListaUsuarios(response.data.empresa.usuarios.map((r, indice) => (

                        <tr key={indice} id={'tr-usuario-'+indice}>
                            <td>{r.nickname}</td>
                            <td>
                                {r.password}
                            </td>
                            <td>
                                {props.id_comercio === 0 ?
                                    <button 
                                        type="button" 
                                        className="btn btn-danger btn-sm" 
                                        title="Eliminar Usuario" 
                                        onClick={() => handleDelteUsuario(indice)}>
                                        <i className="bi bi-trash"></i>
                                    </button>
                                :   ''
                                    /*<button 
                                        type="button" 
                                        className="btn btn-warning btn-sm" 
                                        title="Editar Usuario" 
                                        onClick={() => handleDelteUsuario(indice)}>
                                        <i className="bi bi-pencil"></i>
                                    </button>*/
                                }
                            </td>
                        </tr>
                        
                    )))
                }else{
                    alerta('No fue posible cargar la informacion de la empresa','','warning');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de la empresa  (root/empresa) ->'+ error);
        }finally {
            setSpiner(false);
        }
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 32) {
          event.preventDefault();
        }
    };

    const handleInputEmpresaChange = (event) => {
        const { name, value, type } = event.target;
        if(type === 'checkbox'){
            setEmpresa({ ...empresa, [name]: event.target.checked });
        }else{

            if(name === 'comision_apuesta' || name === 'comision_quinta' || name === 'comision_astros' || name === 'comision_rifa'  || name === 'comision_bonos'){
                var inputValue = value.replace(/\D/g, '');
                setEmpresa({ ...empresa, [name]: inputValue });
            }else{
                setEmpresa({ ...empresa, [name]: value });
            }

        }
    }

    const handleValidarNickname = (event) =>{

        if(event.target.value !== ''){
            validar_nickname(event.target.value).then(response => {
                if(response.status !== 200){
                    alerta('!Atención¡',response.response.data.status_message??response.message,'info');
                    setEmpresa({ ...empresa, ['nickname']: '', ['password']: '' });
                }
            });
        }
    }

    const handleAgregarUsuario = () => {

        if(empresa.nickname == '' || empresa.password == ''){
            alerta('El usuario o contraseña no puede estar vacio', '');
            return;
       }else if(empresa.nickname.length < 4){
            alerta('El usuario debe tener almenos 4 cartacteres', '');
            return;
        }else if(empresa.password.length < 4){
            alerta('La contraseña debe tener al menos 4 caracteres', '');
            return;
        }else if(empresa.usuarios.find(item => item.nickname === empresa.nickname) !== undefined){
            alerta('El usuario '+empresa.nickname+'  ya esta agregado, por favor digite otro.','');
            return;
        }else if(empresa.usuarios.length === 4){
            alerta('Solo es posible crear 4 usuarios', '');
            return;
        }

        let usuario = {
            "nickname":empresa.nickname,
            "password":empresa.password
        }

        empresa.usuarios.push(usuario);

        setListaUsuarios(empresa.usuarios.map((r, indice) => (
            <tr key={indice} id={'tr-usuario-'+indice}>
                <td>{r.nickname}</td>
                <td>
                    {r.password}
                </td>
                <td>
                    {props.id_comercio === 0 ?
                        <button 
                            type="button" 
                            className="btn btn-danger btn-sm" 
                            title="Eliminar Usuario" 
                            onClick={() => handleDelteUsuario(indice)}>
                            <i className="bi bi-trash"></i>
                        </button>
                    :
                        /*<button 
                            type="button" 
                            className="btn btn-warning btn-sm" 
                            title="Editar Usuario" 
                            onClick={() => handleDelteUsuario(indice)}>
                            <i className="bi bi-pencil"></i>
                        </button>*/''
                    }
                </td>
            </tr>
        )))
        setEmpresa({ ...empresa, ['nickname']: '', ['password']: '' });
    }

    const handleDelteUsuario = (indice) => {

        let usuarios = empresa.usuarios
        usuarios.splice(indice, 1);

        setListaUsuarios(empresa.usuarios.map((r, indice) => (
            <tr key={indice} id={'tr-usuario-'+indice}>
                <td>{r.nickname}</td>
                <td>
                    {r.password}
                </td>
                <td>
                    {props.id_comercio === 0 ?
                        <button 
                            type="button" 
                            className="btn btn-danger btn-sm" 
                            title="Eliminar Usuario" 
                            onClick={() => handleDelteUsuario(indice)}>
                            <i className="bi bi-trash"></i>
                        </button>
                    :   ''
                        /*<button 
                            type="button" 
                            className="btn btn-warning btn-sm" 
                            title="Editar Usuario" 
                            onClick={() => handleDelteUsuario(indice)}>
                            <i className="bi bi-pencil"></i>
                        </button>*/
                    }
                </td>
            </tr>
        )))
    }

    const handleGuardarEmpresa = () =>{

        if(empresa.nombre.length < 3){
            alerta('El nombre de la empresa debe tener minimo 3 caracteres', '');
            return;
        }else if(empresa.correo.length < 5){
            alerta('El correo debe tener minimo 5 caracteres', '');
            return;
        }else if(empresa.dominio.length < 10){
            alerta('El dominio debe tener minimo 10 caracteres', '');
            return;
        }else if(empresa.segunda_clave.length < 4 && empresa.id_empresa === 0){
            alerta('La segunda clave debe tener al menos 4 caracteres', '');
            return;
        }else if(empresa.usuarios.length === 0){
            alerta('La empresa debe tener por lo menos un usuario creado', '');
            return;
        }else if(empresa.comision_apuesta > 100 || empresa.comision_quinta > 100 || empresa.comision_astros > 100 || empresa.comision_rifa > 100 ||  empresa.comision_bonos > 100){
            alerta('Las comisiones no pueden ser mayor al 100%', '');
            return;
        }

        Swal.fire({
            title: "¿ Esta seguro de guardar la información de la empresa ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {

                if(empresa.id_empresa === 0){
                    handleCrearEmpresa();
                }else if(empresa.id_empresa > 0){
                    handleEditarEmpresa();
                }
            }
        });
    }

    const handleCrearEmpresa = async () =>{

        setSpiner(true);

        try {
            await crearEmpresa(empresa).then(response => {
                if(response.status === 201){
                    cerrarModal('modalEmpresa');
                    alerta('Empresa creada de forma exitosa','','success');
                    setEmpresa({
                            "id_comercio" : 0,
                            "id_empresa" : 0,
                            "nombre":"",
                            "nombre_contacto":"",
                            "telefono_contacto":"",
                            "correo":"",
                            "estado_id":1,
                            "envia_sms":1,
                            "costo_sms":1,
                            "dominio":"",
                            "resultados_automaticos":1,
                            "realiza_prestamos":1, 
                            "tipo_envio_comprobante_wp":"agenda",
                            "sms_opcional":0,
                            "opera_decimales":0,
                            "comercio_cubierta_id":'',
                            "impresora": 0,
                            "venta_cubierta":0,
                            "monto_minimo_venta":0,
                            "boton_wp":1,        
                            "apuesta":1,
                            "quinta":1,
                            "astros":1,
                            "rifa":0,
                            "bonos":0,
                            "comision_apuesta":100,
                            "comision_quinta":100,
                            "comision_astros":100,
                            "comision_rifa":100,
                            "comision_bonos":35,
                            "nickname":"",
                            "password":"",
                            "segunda_clave":"",
                            "usuarios":[]
                    });
                }else{
                    alerta('No fue posible crear la empresa',response.response.data.status_message??response.message,'error');
                }
            });
        
         }catch (error) {
            alerta('Error no controlado al crear la empresa (root/empres/handleCrearEmpresa) ->'+ error);
        }finally {
            setSpiner(false);
        }
    }

    const handleEditarEmpresa = async () =>{

        setSpiner(true);

        try {
            await EditarEmpresa(empresa).then(response => {
                if(response.status === 200){
                    alerta('Empresa edita de forma exitosa','','success');
                }else{
                    alerta('No fue posible editar la empresa',response.response.data.status_message??response.message,'error');
                }
            });
        
         }catch (error) {
            alerta('Error no controlado al editar la empresa (root/empres/handleEditarEmpresa) ->'+ error);
        }finally {
            setSpiner(false);
        }
    }

    return (
        <React.Fragment>
            <div className="modal fade" id="modalEmpresa" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalEmpresa" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Empresa</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        
                        <div className="modal-body">
                            {spiner ?
                                <div className="d-flex justify-content-center align-items-center my-4 me-4">
                                    <div className="spinner-border" role="status"></div>                 
                                </div>
                            :
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title"><i className="bi bi-building-add me-2"></i>Información General</h5>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-floating mb-3">
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                name="nombre"
                                                                id="nombre" 
                                                                value={empresa.nombre}
                                                                onChange={handleInputEmpresaChange}/>
                                                            <label htmlFor="nombre">Nombre: <span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating mb-3">
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                name="correo"
                                                                id="correo" 
                                                                value={empresa.correo}
                                                                onChange={handleInputEmpresaChange}/>
                                                            <label htmlFor="correo">Correo<span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating mb-3">
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                name="nombre_contacto"
                                                                id="nombre_contacto" 
                                                                value={empresa.nombre_contacto}
                                                                onChange={handleInputEmpresaChange}/>
                                                            <label htmlFor="nombre_contacto">Nombre Contacto</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating mb-3">
                                                            <input 
                                                                type="number" 
                                                                className="form-control" 
                                                                name="telefono_contacto"
                                                                id="telefono_contacto" 
                                                                value={empresa.telefono_contacto}
                                                                onChange={handleInputEmpresaChange}/>
                                                            <label htmlFor="telefono_contacto">Telefono: <span className="text-danger"></span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating mb-3">
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                name="segunda_clave"
                                                                id="segunda_clave" 
                                                                value={empresa.segunda_clave}
                                                                onChange={handleInputEmpresaChange}/>
                                                            <label htmlFor="segunda_clave">Segunda Clave<span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating mb-3">
                                                            <select 
                                                                className="form-select form-select-sm" 
                                                                id="estado_id" 
                                                                name="estado_id" 
                                                                aria-label="State"
                                                                value={empresa.estado_id}
                                                                onChange={handleInputEmpresaChange}
                                                            >
                                                                <option value="1">Activa</option>
                                                                <option value="3">Inactivo</option>
                                                            </select>
                                                            <label htmlFor="estado_id">Estado</label>
                                                        </div>
                                                    </div>                                              
                                                </div>
                                                <h5 className="card-title"><i className="bi bi-person-add me-2"></i>Usuarios:</h5>                                                
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {lista_usuarios.length < 4 ?
                                                            <div className="row">
                                                                <div className="col-md-5">
                                                                    <div className="form-floating mb-3">
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control" 
                                                                            name="nickname"
                                                                            id="nickname" 
                                                                            value={empresa.nickname}
                                                                            onKeyDown={handleKeyDown}
                                                                            onChange={handleInputEmpresaChange}
                                                                            onBlur={handleValidarNickname}/>
                                                                        <label htmlFor="nickname">Usuario: <span className="text-danger">*</span></label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-5">
                                                                    <div className="form-floating mb-3">
                                                                        <input 
                                                                            type="password" 
                                                                            className="form-control" 
                                                                            name="password"
                                                                            id="password" 
                                                                            value={empresa.password}
                                                                            onChange={handleInputEmpresaChange}/>
                                                                        <label htmlFor="password">Contraseña: <span className="text-danger">*</span></label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2 pt-1">
                                                                    <button type="button" className="btn btn-success btn-lg" onClick={handleAgregarUsuario}>Agregar</button>
                                                                </div>
                                                            </div> : ''}
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <table className="table table-sm table-hover table-borderles">
                                                                    {empresa.usuarios.length === 0?
                                                                    <caption>
                                                                        <h5 className='text-secondary text-center'>No hay usuarios agregadas</h5>
                                                                    </caption>:''}
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Usuario</th>
                                                                            <th>Contraseña</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {lista_usuarios}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row"> 
                                                    <h5 className="card-title"><i className="bi bi-gear ms-3 me-2"></i>Configuración</h5>
                                                    <div className="col-md-12 mb-2">
                                                        <div className="form-floating">
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                name="dominio"
                                                                id="dominio" 
                                                                value={empresa.dominio}
                                                                onChange={handleInputEmpresaChange}/>
                                                            <label htmlFor="dominio">Dominio<span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-2">
                                                        <div className="form-floating">
                                                            <input 
                                                                type="number" 
                                                                className="form-control" 
                                                                name="monto_minimo_venta"
                                                                id="monto_minimo_venta" 
                                                                value={empresa.monto_minimo_venta}
                                                                onChange={handleInputEmpresaChange}/>
                                                            <label htmlFor="monto_minimo_venta">Monto Minimo: <span className="text-danger"></span></label>
                                                        </div>
                                                    </div>                                           
                                                    <div className="col-md-12 mb-2">
                                                        <div className="form-floating">
                                                            <select 
                                                                className="form-select form-select-sm" 
                                                                id="tipo_envio_comprobante_wp" 
                                                                name="tipo_envio_comprobante_wp" 
                                                                aria-label="State"
                                                                value={empresa.tipo_envio_comprobante_wp}
                                                                onChange={handleInputEmpresaChange}
                                                            >
                                                                <option value="agenda">Agenda</option>
                                                                <option value="numero">Numero</option>
                                                            </select>
                                                            <label htmlFor="tipo_envio_comprobante_wp">Enviar ticket a:</label>
                                                        </div>
                                                    </div>                                                
                                                    <div className="col-md-12 mb-2">
                                                        <div className="form-check form-switch">
                                                            <input onChange={handleInputEmpresaChange} className="form-check-input" type="checkbox" role="switch" id="envia_sms" name="envia_sms" checked={empresa.envia_sms} />
                                                            <label className="form-check-label" htmlFor="envia_sms">Enviar SMS</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-2">
                                                        <div className="form-check form-switch">
                                                            <input onChange={handleInputEmpresaChange} className="form-check-input" type="checkbox" role="switch" id="sms_opcional" name="sms_opcional" checked={empresa.sms_opcional} />
                                                            <label className="form-check-label" htmlFor="sms_opcional">Boton SMS</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-2">
                                                        <div className="form-check form-switch">
                                                            <input onChange={handleInputEmpresaChange} className="form-check-input" type="checkbox" role="switch" id="boton_wp" name="boton_wp" checked={empresa.boton_wp}/>
                                                            <label className="form-check-label" htmlFor="boton_wp">Boton WhatsApp</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-2">
                                                        <div className="form-check form-switch">
                                                            <input onChange={handleInputEmpresaChange} className="form-check-input" type="checkbox" role="switch" id="resultados_automaticos" name="resultados_automaticos" checked={empresa.resultados_automaticos}/>
                                                            <label className="form-check-label" htmlFor="resultados_automaticos">Resultados Automaticos</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-2">
                                                        <div className="form-check form-switch">
                                                            <input onChange={handleInputEmpresaChange} className="form-check-input" type="checkbox" role="switch" id="realiza_prestamos" name="realiza_prestamos" checked={empresa.realiza_prestamos}/>
                                                            <label className="form-check-label" htmlFor="realiza_prestamos">Modulo de Prestamos</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-2">
                                                        <div className="form-check form-switch">
                                                            <input onChange={handleInputEmpresaChange} className="form-check-input" type="checkbox" role="switch" id="opera_decimales" name="opera_decimales" checked={empresa.opera_decimales}/>
                                                            <label className="form-check-label" htmlFor="opera_decimales">Decimales</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-2">
                                                        <div className="form-check form-switch">
                                                            <input onChange={handleInputEmpresaChange} className="form-check-input" type="checkbox" role="switch" id="impresora" name="impresora" checked={empresa.impresora}/>
                                                            <label className="form-check-label" htmlFor="impresora">Impresora</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-2">
                                                        <div className="form-check form-switch">
                                                            <input onChange={handleInputEmpresaChange} className="form-check-input" type="checkbox" role="switch" id="venta_cubierta" name="venta_cubierta" checked={empresa.venta_cubierta}/>
                                                            <label className="form-check-label" htmlFor="venta_cubierta">Cubierta Automatica</label>
                                                        </div>
                                                    </div>
                                                    {empresa.venta_cubierta?
                                                    <div className="col-md-12 mb-2">
                                                        <div className="form-floating">
                                                            <input 
                                                                type="number" 
                                                                className="form-control" 
                                                                name="comercio_cubierta_id"
                                                                id="comercio_cubierta_id" 
                                                                value={empresa.comercio_cubierta_id}
                                                                onChange={handleInputEmpresaChange}/>
                                                            <label htmlFor="comercio_cubierta_id">Id Comercio Cubierta: <span className="text-danger"></span></label>
                                                        </div>
                                                    </div>
                                                    :''}
                                                </div>
                                                <div>
                                                    <h5 className="card-title"><i className="bi bi-dice-6 ms-3 me-2"></i>Modalidades</h5>

                                                    <div className="col-5">
                                                        <label className="form-check-label" htmlFor="bonos">Apuesta:</label>
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-text">
                                                                <div className="form-check form-switch">
                                                                    <input 
                                                                        onChange={handleInputEmpresaChange} 
                                                                        className="form-check-input" 
                                                                        type="checkbox" 
                                                                        role="switch" 
                                                                        id="apuesta" 
                                                                        name="apuesta" 
                                                                        checked={empresa.apuesta}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <input 
                                                                type="text"
                                                                maxLength={3} 
                                                                className="form-control" 
                                                                id="comision_apuesta"
                                                                name="comision_apuesta"
                                                                disabled={true}
                                                                value={empresa.comision_apuesta}
                                                                onChange={handleInputEmpresaChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-5">
                                                        <label className="form-check-label" htmlFor="bonos">Quinta:</label>
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-text">
                                                                <div className="form-check form-switch">
                                                                    <input 
                                                                        onChange={handleInputEmpresaChange} 
                                                                        className="form-check-input" 
                                                                        type="checkbox" 
                                                                        role="switch" 
                                                                        id="quinta" 
                                                                        name="quinta" 
                                                                        checked={empresa.quinta}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <input 
                                                                type="text"
                                                                maxLength={3} 
                                                                className="form-control" 
                                                                id="comision_quinta"
                                                                name="comision_quinta"
                                                                disabled={true}
                                                                value={empresa.comision_quinta}
                                                                onChange={handleInputEmpresaChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-5">
                                                        <label className="form-check-label" htmlFor="bonos">Astros:</label>
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-text">
                                                                <div className="form-check form-switch">
                                                                    <input 
                                                                        onChange={handleInputEmpresaChange} 
                                                                        className="form-check-input" 
                                                                        type="checkbox" 
                                                                        role="switch" 
                                                                        id="astros" 
                                                                        name="astros" 
                                                                        checked={empresa.astros}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <input 
                                                                type="text"
                                                                maxLength={3} 
                                                                className="form-control" 
                                                                id="comision_astros"
                                                                name="comision_astros"
                                                                disabled={true}
                                                                value={empresa.comision_astros}
                                                                onChange={handleInputEmpresaChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-5">
                                                        <label className="form-check-label" htmlFor="bonos">Rifa:</label>
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-text">
                                                                <div className="form-check form-switch">
                                                                    <input 
                                                                        onChange={handleInputEmpresaChange} 
                                                                        className="form-check-input" 
                                                                        type="checkbox" 
                                                                        role="switch" 
                                                                        id="rifa" 
                                                                        name="rifa" 
                                                                        checked={empresa.rifa}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <input 
                                                                type="text"
                                                                maxLength={3} 
                                                                className="form-control" 
                                                                id="comision_rifa"
                                                                name="comision_rifa"
                                                                disabled={true}
                                                                value={empresa.comision_rifa}
                                                                onChange={handleInputEmpresaChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-5">
                                                        <label className="form-check-label" htmlFor="bonos">Bono:</label>
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-text">
                                                                <div className="form-check form-switch">
                                                                    <input 
                                                                        onChange={handleInputEmpresaChange} 
                                                                        className="form-check-input" 
                                                                        type="checkbox" 
                                                                        role="switch" 
                                                                        id="bonos" 
                                                                        name="bonos"  
                                                                        checked={empresa.bonos}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <input 
                                                                type="text"
                                                                maxLength={3} 
                                                                className="form-control" 
                                                                id="comision_bonos"
                                                                name="comision_bonos"
                                                                value={empresa.comision_bonos}
                                                                onChange={handleInputEmpresaChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">                            
                            <button type="button" className="btn btn-primary" onClick={handleGuardarEmpresa}>Guardar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapSateToProps = state => {

    return {
        tablaPlanesPago: state.tablaPlanesPago,
        listaZonas: state.listaZonas,
        data_sesion: state.data_sesion,
        id_comercio: state.id_comercio
    }
}

export default  connect(mapSateToProps,null)(Formulario);